import Header from "@ui/ComponentUtils/Header";
import React from "react";

import { createFilterOptions } from "@mui/material/Autocomplete";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useFormContext } from "react-hook-form";
import enums from "helpers/enums";
import MainSection from "@ui/MuiComponents/Section/MainSection";
import Label from "@ui/components/Label";
import { Grid, TextField, Button } from "@material-ui/core/index";
import { isAdmin, isSuperAdmin } from "@ui/Utils/helper";

const filter = createFilterOptions();

const DetailHeader = ({
  newVendorCode,
  setNewVendorCode,
  isEditable,
  onSubmit,
  setIsEditable,
}) => {
  const formMethods = useFormContext();
  const values = formMethods.watch();
  const model = enums.models["vendor-portal"].vendorCodes;

  const params = useParams();
  const navigate = useNavigate();

  const handleCancel = () => {
    if (window.confirm("Are you sure to cancel? Changes will be discarded.")) {
      if (params?.code === "create") {
        navigate("/" + enums.models.masterData);
      } else {
        formMethods.reset();
        setIsEditable(false);
      }
    }
  };

  const links = [];

  // const actions = [
  //   {
  //     label: "Cancel",
  //     iconName: "cancel",
  //     hidden: !isEditable,
  //     onClick: handleCancel,
  //   },
  //   {
  //     label: "Save",
  //     iconName: "save",
  //     hidden: !isEditable,
  //     type: "submit",
  //     onClick: ,
  //   },
  //   {
  //     label: "Edit",
  //     iconName: "edit",
  //     hidden: isEditable,
  //     onClick: () => {
  //       setIsEditable(true);
  //     },
  //   },
  // ];

  return (
    <>
      <Header links={links} pageTitle={"Vendor Code"} actions={[]} />

      <MainSection title="Available Codes">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Label value={(values?.availableCodes || []).join(", ")} />
          </Grid>

          <Grid item xs={12} container>
            <Grid item xs={6}>
              <TextField
                label="Add New Vendor Code"
                value={newVendorCode}
                style={{ width: "100%" }}
                onChange={(e) =>
                  setNewVendorCode(e.target.value?.toUpperCase())
                }
              />
            </Grid>

            <Grid item xs={6}>
              {(isAdmin || isSuperAdmin) && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={formMethods.handleSubmit(onSubmit)}
                >
                  Add To Set
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
      </MainSection>
    </>
  );
};

export default DetailHeader;
