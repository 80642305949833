import HTTP from "@ui/Utils/http";
import commonConfig from "config/commonConfig";
import enums from "helpers/enums";

const baseApiUrl = commonConfig.baseApiUrl;

export const globalUserControl = async (
  model,
  action = {
    users: [], // to signout 1 user, send array of 1 element.. with array of _ids' or array of objects with _id key...
    selectAll: false,
  }
) => {
  try {
    const url = baseApiUrl + model + "/globalUserControl"; // // By Default this API signsout all Users according to appModules param value

    const res = await HTTP.post(url, action);

    return res?.data?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const dynamicApiCall = async (url, method, body, additionalHeaders) => {
  try {
    const res = await HTTP[method](url, body, additionalHeaders);
    return res?.data?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const notifyUsers = async (model, body = {}) => {
  try {
    const url = baseApiUrl + model + "/notify";

    const res = await HTTP.post(url, body);
    return res?.data?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const validateToken = async (model, { token } = {}) => {
  try {
    const url = baseApiUrl + model + "/validateToken";

    const res = await HTTP.post(url, { token });
    return res?.data?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getPaginatedData = async (
  model,
  { page, pageSize, sort, criteria, ...query } = {},
  additionalHeaders
) => {
  try {
    const url = baseApiUrl + model + "/paginate";

    const params = {
      ...query,
      pageNo: page + 1,
      perPageRecords: pageSize || 10,
      criteria: criteria || null,
      sort: (sort && JSON.stringify(sort)) || null,
    };

    const res = await HTTP.post(url, params, additionalHeaders);
    return res?.data?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

// for actual pagination, use above function.. not below.. kuch bee it is..
export const paginate = async (model, criteria = {}, extras) => {
  try {
    const url = baseApiUrl + model + "/paginate";

    const res = await HTTP.post(url, { criteria, ...extras });
    return res?.data?.data?.records;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const advanceDesignFilter = async (model, formData) => {
  try {
    const url = baseApiUrl + model + "/advanceDesignFilter";

    const res = await HTTP.post(url, formData, {
      "Content-Type": "multipart/form-data",
    });
    return res?.data?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const paginateWithHook = async (
  model,
  { page, pageSize, sort, criteria, ...query } = {},
  queryParams
) => {
  try {
    const url =
      baseApiUrl +
      model +
      `/paginate?hook=true${queryParams ? `&${queryParams}` : ""}`;

    const params = {
      ...query,
      pageNo: (page || 0) + 1,
      perPageRecords: pageSize || 10,
      criteria: criteria || null,
      sort: (sort && JSON.stringify(sort)) || null,
    };

    const res = await HTTP.post(url, params);
    return res?.data?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const customPaginateWithHook = async (
  model,
  { page, pageSize, sort, criteria, ...query } = {}
) => {
  try {
    const url = baseApiUrl + "c/" + model + "/customPaginate?hook=true";

    const params = {
      ...query,
      pageNo: page + 1,
      perPageRecords: pageSize || 10,
      criteria: criteria || null,
      sort: (sort && JSON.stringify(sort)) || null,
    };

    const res = await HTTP.post(url, params);
    return res?.data?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const search = async (model, criteria = {}, additionalHeaders) => {
  try {
    const url = baseApiUrl + model + "/search";

    const res = await HTTP.post(url, criteria, additionalHeaders);
    return res?.data?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const searchWithHook = async (
  model,
  criteria = {},
  additionalHeaders
) => {
  try {
    const url = baseApiUrl + model + "/search?hook=true";

    const res = await HTTP.post(url, criteria, additionalHeaders);
    return res?.data?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getByCode = async (model, code, queryParams) => {
  try {
    const url =
      baseApiUrl + model + "/" + code + (queryParams ? `?${queryParams}` : "");

    const res = await HTTP.get(url);
    return res?.data?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getSingleRecord = async (model, criteria = {}) => {
  try {
    const url = baseApiUrl + model + "/getSingleRecord";

    const res = await HTTP.post(url, criteria);
    return res?.data?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getSingleRecordWithHook = async (model, criteria = {}) => {
  try {
    const url = baseApiUrl + model + "/getSingleRecord?hook=true";

    const res = await HTTP.post(url, criteria);
    return res?.data?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};
// CRU
// POST: https://e-creatives-dev.de.r.appspot.com/api/e-creatives/orders ; CREATE
// PUT: https://e-creatives-dev.de.r.appspot.com/api/e-creatives/orders/:code ; UPDATE
// GET: https://e-creatives-dev.de.r.appspot.com/api/e-creatives/orders/:code ; GET BY CODE

// ACTIONS
// method: POST
// https://e-creatives-dev.de.r.appspot.com/api/e-creatives/orders/getSingleRecord
// https://e-creatives-dev.de.r.appspot.com/api/e-creatives/orders/search req body is only like mongooseModel.find({body})
// https://e-creatives-dev.de.r.appspot.com/api/e-creatives/orders/paginate req body is only like mongooseModel.find({body})

// {name: {$regex: "MOCP2i", $options: 'i'}}
// {page, perPageRecords, criteria: [{$or: {name: 'abhi'}}, {}]}
export const createRecord = async (model, data, additionalHeaders) => {
  try {
    const res = await HTTP.post(baseApiUrl + model, data, additionalHeaders);

    return res?.data?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const createRecordWithHook = async (model, data, additionalHeaders) => {
  try {
    const res = await HTTP.post(
      baseApiUrl + model + "?hook=true",
      data,
      additionalHeaders
    );

    return res?.data?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const updateRecord = async (model, data) => {
  try {
    const res = await HTTP.put(baseApiUrl + model + "/" + data?.code, data);

    return res?.data?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const updateRecordWithHook = async (model, data, additionalHeaders) => {
  try {
    const res = await HTTP.put(
      baseApiUrl + model + "/" + data?.code + "?hook=true",
      data,
      additionalHeaders
    );

    return res?.data?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const updateListRecords = async (model, data) => {
  try {
    const res = await HTTP.post(baseApiUrl + model + "/updateList", { data });

    return res?.data?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const updateIAMRecord = async (
  model,
  data,
  { additionalHeaders } = {}
) => {
  try {
    const url = commonConfig.iamBaseURL + "iam/" + model + "/" + data?.code;

    const res = await HTTP.put(url, data, {
      additionalHeaders,
    });

    return res?.data?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const softDeleteRecord = async (model, code, hardDelete) => {
  try {
    const url = baseApiUrl + model + "/" + code;
    const res = await HTTP.delete(url, { hardDelete });

    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const uploadFiles = async (model, files, params = {}) => {
  try {
    let url = commonConfig.microservices.upload + model + "/upload";

    if (params.additionalPath) {
      url += "?additionalPath=" + params.additionalPath;
    }

    if (params.additionalPath && params.code) {
      url += "&code=" + params.code;
    } else if (params.code) {
      url += "?code=" + params.code;
    }
    if ((params.additionalPath || params.code) && params.replace) {
      url += "&replace=true";
    } else if (params.replace) {
      url += "?replace=true";
    }

    const res = await HTTP.upload(url, files);

    return res?.data?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const verifyUploadFiles = async (model, files, params = {}) => {
  try {
    // const res = await HTTP.post(url, formData, {
    //   "content-type": "multipart/form-data",
    // });
    let url = commonConfig.microservices.upload + model + "/upload?verify=true";
    if (params.additionalPath) {
      url += "&additionalPath=" + params.additionalPath;
    }
    const res = await HTTP.upload(url, files);

    return res?.data?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const uploadFilesWithHook = async (model, files, params = {}) => {
  try {
    let url = commonConfig.microservices.upload + model + "/upload?hook=true";
    if (params.additionalPath) {
      url += "&additionalPath=" + params.additionalPath;
    }

    const res = await HTTP.upload(url, files);

    return res?.data?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const uniqueDesigns = async ({
  criteria = {
    // stockStatus: enums.stockStatus.inventory, // only used in OP Specials screen till now.. so take care when this default is removed...// now used in Catalogues Screen, e-creatives.. taken care by having it in params.. :P
  },
} = {}) => {
  try {
    const url = baseApiUrl + enums.models.products + "/uniqueDesigns";

    const res = await HTTP.post(url, criteria);
    return res?.data?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getAvailableProductsQuantity = async ({
  styleNo,
  stockStatus = enums.stockStatus.inventory,
  additionalHeaders,
}) => {
  try {
    const res = await HTTP.post(
      commonConfig.baseApi?.["e-creatives"] +
        "c/" +
        enums.models["data-engine"].products +
        "/getAvailableProductsQuantity",
      { styleNo, stockStatus },
      additionalHeaders
    );

    return res?.data?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const urlToBase64 = async (model, url) => {
  try {
    const res = await HTTP.post(baseApiUrl + model + "/urlToBase64", {
      url: url,
    });

    return res?.data?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const countDocuments = async (
  model
  // criteria = {} // not handled in backend yet.. its easy anyway
) => {
  try {
    const url = baseApiUrl + model + "/countDocuments";

    const res = await HTTP.post(url);
    return res?.data?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const bulkImportData = async (model, body = {}, { params }) => {
  try {
    let url = baseApiUrl + model + "/bulkImportData";

    if (params) {
      url += `?${params}`;
    }

    const res = await HTTP.post(url, body);
    return res?.data?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const signStoredObjects = async (
  model,
  {
    codes = [],
    filterColumns = {
      // fieldWhichNeedsToBeSignedForImage: 1
    },
  }
) => {
  try {
    const url = baseApiUrl + model + "/signStoredObjects";

    const res = await HTTP.post(url, { code: { $in: codes }, filterColumns });
    return res?.data?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const bulkStockUpsert = async (model, body = {}) => {
  try {
    const url = baseApiUrl + model + "/bulkStockUpsert";

    const res = await HTTP.post(url, body);
    return res?.data?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const distinctFieldValues = async (
  model,
  criteria = {
    field: "",
    //"field required to get distinct values from a collection of records"
  },
  additionalHeaders
) => {
  try {
    const url = baseApiUrl + model + "/distinctFieldValues";

    const res = await HTTP.post(url, criteria, additionalHeaders);
    return res?.data?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};
