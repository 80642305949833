import React from "react";

// icons
import DashboardIcon from "@mui/icons-material/Dashboard";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import InsertEmoticonIcon from "@mui/icons-material/InsertEmoticon";
import ShopOutlinedIcon from "@mui/icons-material/ShopOutlined";
import ShopIcon from "@mui/icons-material/Shop";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ShoppingBasketOutlinedIcon from "@mui/icons-material/ShoppingBasketOutlined";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";

import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import HailIcon from "@mui/icons-material/Hail";
import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import SettingsSuggestOutlinedIcon from "@mui/icons-material/SettingsSuggestOutlined";
import InventoryIcon from "@mui/icons-material/Inventory";

import CloudSyncIcon from "@mui/icons-material/CloudSync";
import CloudCircleIcon from "@mui/icons-material/CloudCircle";
import FilterDramaOutlinedIcon from "@mui/icons-material/FilterDramaOutlined";
import StarsIcon from "@mui/icons-material/Stars";
import FunctionsOutlinedIcon from "@mui/icons-material/FunctionsOutlined";
import FunctionsIcon from "@mui/icons-material/Functions";
// ABHISHEK: Use this icon for Sync Feature

// Components
import HomePage from "pages/HomePage/HomePage";

// utils
import {
  isAdmin,
  isSuperAdmin,
  isVendor,
  userHasAccess,
} from "@ui/Utils/helper";
import enums from "helpers/enums";
import VendorsList from "@ui/views/vendor-portal/Vendors/List/List";
import VendorDetail from "@ui/views/vendor-portal/Vendors/Detail/Detail";

import QuotationDetail from "@ui/views/vendor-portal/Quotation/Detail/Detail";
import DumpsList from "views/Dumps/List/List";
import SettingsList from "@ui/views/vendor-portal/Settings/List/List";
import QuotationsList from "@ui/views/vendor-portal/Quotation/List/List";
import ConfigurationDetail from "views/Configurations/Detail/Detail";
import OrdersList from "@ui/views/e-creatives/Orders/List/List";
import OrderDetail from "@ui/views/e-creatives/Orders/Detail/Detail";
import VendorCodesList from "@ui/views/vendor-portal/VendorCodes/List/List";
import VendorCodeDetail from "@ui/views/vendor-portal/VendorCodes/Detail/Detail";
import StockOrdersList from "@ui/views/e-creatives/StockOrders/List/List";
import StockOrderDetail from "@ui/views/e-creatives/StockOrders/Detail/Detail";
import CustomOrdersList from "@ui/views/e-creatives/CustomOrders/List/List";
import CustomOrderDetail from "@ui/views/e-creatives/CustomOrders/Detail/Detail";

const screenAccess = isAdmin || isSuperAdmin;

export const primaryMenuRoutes = [
  {
    access: true,
    title: "Dashboard",
    path: enums.models.dashboard,
    icon: () => <DashboardOutlinedIcon />,
    selectedIcon: () => <DashboardIcon style={styles.selectedIcon} />,
    component: () => <HomePage />,
  },
  {
    access: screenAccess || isVendor,
    title: "Exhibition",
    path: enums.models.quotations,
    hideMenuByDefault: true,
    icon: () => <ShopOutlinedIcon />,
    selectedIcon: () => <ShopIcon />,

    component: () =>
      screenAccess || isVendor ? <QuotationsList /> : <HomePage />,
  },
  {
    access: screenAccess || isVendor,
    title: "E-commerce",
    path: enums.models["e-creatives"].orders,
    icon: () => <ShoppingCartOutlinedIcon />,
    selectedIcon: () => <ShoppingCartIcon style={styles.selectedIcon} />,
    component: () =>
      screenAccess || isVendor ? (
        <OrdersList hideTabs={{ exhibition: true }} />
      ) : (
        <HomePage />
      ),
  },
  {
    access: screenAccess || isVendor,
    title: "Stock Os",
    path: enums.models["e-creatives"].stockOrders,
    icon: () => <ShoppingBasketOutlinedIcon />,
    selectedIcon: () => <ShoppingBasketIcon />,
    hideMenuByDefault: true,
    component: () =>
      screenAccess || isVendor ? <StockOrdersList /> : <HomePage />,
  },
  {
    access: true,
    title: "Custom Os",
    path: enums.models["e-creatives"].customOrders,
    icon: () => <FunctionsOutlinedIcon />,
    selectedIcon: () => <FunctionsIcon />,
    hideMenuByDefault: true,
    component: () => <CustomOrdersList />,
  },
  {
    access: screenAccess,
    title: "Vendors",
    path: enums.models["vendor-portal"].users,
    icon: () => <HailIcon />,
    selectedIcon: () => <HailIcon style={styles.selectedIcon} />,
    component: () => (screenAccess ? <VendorsList /> : <HomePage />),
  },
  // {
  //   access: isAdmin || isSuperAdmin,
  //   title: "Configuration",
  //   path: enums.models.configurations,
  //   icon: () => <SettingsSuggestOutlinedIcon />,
  //   selectedIcon: () => <SettingsSuggestIcon style={styles.selectedIcon} />,
  //   component: () => <ConfigurationsList />,
  // },

  {
    access: screenAccess,
    title: "V Codes",
    path: enums.models.masterData,
    icon: () => <StarsIcon />,
    component: () => (screenAccess ? <VendorCodesList /> : <HomePage />),
  },
  {
    access: screenAccess,
    title: "Settings",
    path: enums.models.settings,
    icon: () => <SettingsSuggestOutlinedIcon />,
    selectedIcon: () => <SettingsSuggestIcon style={styles.selectedIcon} />,
    component: () => (screenAccess ? <SettingsList /> : <HomePage />),
  },
  {
    access: isSuperAdmin,
    title: "Dumps",
    path: enums.models.dumps,
    icon: () => <FilterDramaOutlinedIcon />,
    selectedIcon: () => <CloudCircleIcon style={styles.selectedIcon} />,
    component: () => (isSuperAdmin ? <DumpsList /> : <HomePage />),
  },
];

export const secondaryMenuRoutes = [
  {
    access: screenAccess,
    title: "Configuration",
    path: enums.models.configurations + "/:code",
    component: () => (screenAccess ? <ConfigurationDetail /> : <HomePage />),
  },
  {
    access: screenAccess || isVendor,
    title: "Quotation",
    path: enums.models.quotations + "/:code",
    component: () =>
      screenAccess || isVendor ? <QuotationDetail /> : <HomePage />,
  },
  {
    access: screenAccess || isVendor,
    title: "Order",
    path: enums.models["e-creatives"].orders + "/:code",
    component: () =>
      screenAccess || isVendor ? <OrderDetail /> : <HomePage />,
  },
  {
    access: screenAccess || isVendor,
    title: "Stock Order",
    path: enums.models["e-creatives"].stockOrders + "/:code",
    component: () =>
      screenAccess || isVendor ? <StockOrderDetail /> : <HomePage />,
  },
  {
    access: true,
    title: "Custom Order",
    path: enums.models["e-creatives"].customOrders + "/:code",
    component: () => (true ? <CustomOrderDetail /> : <HomePage />),
  },
  {
    access: screenAccess,
    title: "Vendors",
    path: enums.models["vendor-portal"].users + "/:code",
    component: () => (screenAccess ? <VendorDetail /> : <HomePage />),
  },
  {
    access: screenAccess,
    title: "V Codes",
    path: enums.models["vendor-portal"].vendorCodes + "/:code",
    component: () => <VendorCodeDetail />,
  },
];

const styles = {
  selectedIcon: { color: "black", fontSize: 30 },
};
