const enums = {
  orderStatus: {
    inReview: "In review",
    accepted: "Accepted",
    making: "Making",
    dispatched: "Dispatched",
    delivered: "Delivered",
    returned: "Returned",
    canceled: "Canceled",
  },

  stockStatus: {
    staged: "Staged",
    inventory: "In Inventory",
    approval: "In Approval",
    issued: "Issued",
  },

  "vendor-portal": {
    orderStatus: {
      pending: "Pending",
      assigned: "Assigned",
      accepted: "Accepted",
      inProgress: "In Progress",
      delivered: "Delivered",
      resent: "Resent",
      canceled: "Canceled",
    },

    // Clone to e-creatives project as well
    pdfTemplates: {
      template1: "Template-1",
      template2: "Template-2",
      template3: "Template-3",
    },
  },

  keywords: {
    selectAll: "Select All",
    includedUsers: {
      public: "All Unregistered Users",
      private: "All Registered Users",
    },
    dataEngine: "Data-Engine",
  },

  workflow: {
    wholesale: "Wholesale",
    productWise: "Product Wise",
  },

  roles: {
    superAdmin: "Super Admin",
    admin: "Admin",
    manager: "Manager",
    branch: "Branch",
    zoneManager: "Zone Manager",
    inventoryManager: "Inventory Manager",
    salesHead: "Sales Head",
    salesPerson: "Sales Person",
    vendor: "Vendor",
    customer: "Customer",
  },

  fieldTypes: {
    textField: "textField",
    dropdown: "dropdown",
    asyncDropdown: "asyncDropdown",
    upload: "upload",
  },
  dataTypes: {
    string: "string",
    number: "number",
    object: "object",
    array: "array",
  },

  models: {
    iam: {
      users: "iam/users",
      clients: "iam/clients",
    },
    "vendor-portal": {
      quotations: "vendor-portal/quotations",
      users: "vendor-portal/users",
      dumps: "vendor-portal/dumps",
      configurations: "vendor-portal/configurations",
      settings: "vendor-portal/settings",
      vendorCodes: "vendor-portal/vendorCodes",
    },
    "e-creatives": {
      orders: "e-creatives/orders",
      stockOrders: "e-creatives/stockOrders",
      customOrders: "e-creatives/customOrders", // OP VP
    },
    "data-engine": {
      products: "data-engine/products",
      purity: "data-engine/purity",
      collectionLines: "data-engine/collectionLines",
      category: "data-engine/category",
      subCategory: "data-engine/subCategory",
      users: "data-engine/users", // All Customers from all apps will be here
    },
    divisions: "data-engine/divisions",
    products: "data-engine/products",
    dashboard: "vendor-portal/dashboard",
    masterData: "vendor-portal/masterData", // not a model, just for route
    quotations: "vendor-portal/quotations",
    users: "vendor-portal/users",
    dumps: "vendor-portal/dumps",
    configurations: "vendor-portal/configurations",
    settings: "vendor-portal/settings",
    vendorCodes: "vendor-portal/vendorCodes",
  },

  dataSource: {
    orderPro: "Order Pro",
    orderProMobile: "Order Pro Mobile",
    orderProAdminMobile: "Order Pro Admin Mobile",
  },

  customizationType: {
    product: "product",
    scratch: "scratch",
  },

  ordersType: {
    stockOrder: "Stock Order", // orders placed by Employees in OP Admin mobile App
    customerOrder: "Customer Order", // orders placed by Customers in OP mobile app
  },

  orderPriority: {
    ordered: "Ordered",
    makeToOrder: "Make To Order",
  },

  customOrderPriority: { normal: "Normal", urgent: "Urgent" },
  customOrderType: {
    custom: "Custom",
    online: "Online",
  },

  screws: {
    bombay: "Bombay",
    south: "South",
  },
};

export default enums;
