import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import CustomEventEmitter from "@ui/Utils/CustomEventEmitter";
import {
  createRecord,
  getByCode,
  updateRecord,
} from "@ui/ComponentUtils/blueprintAPIs";

import enums from "helpers/enums";
import DetailHeader from "./components/DetailHeader";

const validationSchema = yup.object().shape({});

const entitySchema = {};

const VendorCodeDetail = () => {
  const formMethods = useForm({
    defaultValues: entitySchema,
    mode: "all",
    resolver: yupResolver(validationSchema),
  });
  const navigate = useNavigate();

  const params = useParams();
  const model = enums.models["vendor-portal"].vendorCodes;
  const [newVendorCode, setNewVendorCode] = useState("");
  const [isEditable, setIsEditable] = useState(false);

  useEffect(() => {
    (async () => {
      const id = params?.code;

      try {
        if (id !== "create") {
          const res = await getByCode(model, id);

          formMethods.reset(res);
          setIsEditable(false);
        } else {
          setIsEditable(true);
        }
      } catch (err) {
        navigate("/" + enums.models.masterData);
        console.log(err);
      }
    })();
  }, [params?.code]);

  const onSubmit = async (values, metaData) => {
    try {
      let res = {},
        action = "Created";

      if (
        !newVendorCode?.toString().trim() ||
        !/^[a-zA-Z0-9\s]+$/.test(newVendorCode)
      ) {
        return CustomEventEmitter.emit(
          "alert_error",
          "Vendor Code should contain only ALPHABETS / NUMBERS."
        );
      }

      if (!values.code) {
        values = { availableCodes: [newVendorCode] };

        res = await createRecord(model, values);
        navigate(`/${model}/${res?.code}`);
      } else {
        if (
          (values.availableCodes || []).find((avail) => avail === newVendorCode)
        ) {
          return CustomEventEmitter.emit(
            "alert_error",
            newVendorCode + " already exists."
          );
        }
        values = {
          code: values.code,
          $addToSet: { availableCodes: { $each: [newVendorCode] } },
        };
        res = await updateRecord(model, values);
        action = "Updated";
      }
      setIsEditable(false);
      formMethods.reset(res);
      setNewVendorCode("");
      CustomEventEmitter.emit("alert_success", "Successfully " + action);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <FormProvider {...formMethods}>
      <DetailHeader
        isEditable={isEditable}
        onSubmit={onSubmit}
        setIsEditable={setIsEditable}
        newVendorCode={newVendorCode}
        setNewVendorCode={setNewVendorCode}
      />

      {/* <DetailContent isEditable={isEditable} /> */}
    </FormProvider>
  );
};

export default VendorCodeDetail;
